<template>
  <a-row class="fulfillment">
    <div class="position-absolute mt-2 pbz-font body-md-bold">
      Delivery Order
    </div>
    <a-col :span="24" class="d-flex justify-content-center align-items-center">
      <div class="search-box mx-2">
        <SearchIcon class="i" style="color: #999999" />
        <input
          v-model="querySearch"
          class="field"
          type="text"
          :placeholder="$t('fulfillment.search')"
          @input="handleSearch"
        />
      </div>

      <div class="mx-2 filter-box" @click="handleFilter()">
        <div>
          <FilterIcon style="color: #999999" />
        </div>
        <div class="caption">
          {{ $t('utils.filter') }}
        </div>
      </div>
      <a-modal :visible="filter" :closable="false" :footer="null">
        <FilterList
          :reset="resetFilter"
          :clear="clear"
          @setLoading="setLoading"
          @visible="cancelModal($event)"
          @setWarehouseId="setWarehouseId($event)"
        />
      </a-modal>
    </a-col>

    <a-col v-if="getFilterParam" class="categories justify-content-center" style="margin-top: 60px;">
      <div
        v-for="item in activeFilter"
        :key="item"
        class="list active pbz-font overline-medium d-inline"
        style="cursor: text;"
      >
        {{ item }}
      </div>
      <a-button
        class="cancel-button pbz-font button-sm-medium d-inline"
        style="width: auto; height:auto; border:none;"
        @click="resetFilter"
      >
        {{ $t('utils.clearFilter') }}
      </a-button>
    </a-col>

    <a-col :span="24" class="categories d-flex justify-content-start align-items-start text-left">
      <div
        v-for="(item, index) in categorie_state"
        :key="index"
        class="list"
        :class="activeCategories === item.value && 'active'"
        @click.prevent="changeCategorieState(item.value)"
      >
        {{ item.title }}
      </div>
    </a-col>

    <a-col v-if="fulfillment.length" :span="24" class="d-flex justify-content-start align-items-start" style="margin-top: 15px">
      <div class="pbz-font body-md-bold">
        {{ $t('fulfillment.totalFulfillment', { value: total_row }) }}
      </div>
    </a-col>

    <a-col v-if="fulfillment.length" :span="24" style="padding: 10px 20px">
      <a-row>
        <a-col :span="12" class="d-flex justify-content-start align-items-center">
          <div>
            <a-checkbox
              v-if="
                !isMasterFulfillment &&
                  ![
                    'all',
                    'good_issued',
                    'canceled',
                    'cancel_requested',
                  ].includes(activeCategories)
              "
              :indeterminate="indeterminate_checked"
              :checked="all_item_checked"
              class="pbz-font body-md-bold"
              style="color: #999999"
              @change="checkboxSelectAllFulfillment"
            >
              {{ $t('fulfillment.all') }}
            </a-checkbox>
          </div>

          <div v-if="show_button_bulk && !isMasterFulfillment" style="cursor: pointer" class="d-flex mx-3">
            <div class="mr-2">
              <img
                src="https://ik.imagekit.io/powerbiz/saas/Icons__2_.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1642492365818"
              />
            </div>
            <div class="ml-2 pbz-font body-md-bold" style="color: #0559cb" @click.prevent="handleButtonAcceptBulkOrder">
              {{ captionBulkyUpdate }}
            </div>
          </div>
        </a-col>
      </a-row>

      <a-modal :visible="showModalNotifToSetFilter" :footer="null" @cancel="() => (showModalNotifToSetFilter = false)">
        <ContentModalNotifToSetFilter
          @clickSetFilter="() => (filter = true, showModalNotifToSetFilter = false)"
          @clickCancelModal="() => (showModalNotifToSetFilter = false)"
        />
      </a-modal>
    </a-col>

    <a-col v-if="tempFulfillment.length" :span="24">
      <a-button
        v-if="tempFulfillment.length"
        type="primary"
        :disabled="loadingList"
        @click="handleSubmitToWms"
      >
        Submit to WMS
      </a-button>
    </a-col>

    <a-col class="table-responsive pb-2" :style="{ maxHeight: screenHeight + 'px' }">
      <div
        class="container-fluid border rounded py-2 mx-0 mt-3"
        :style="{ minWidth: listWidth + 'px' }"
      >
        <div class="row flex-nowrap row-calc">
          <div
            v-for="tr in [
              'checkbox',
              'order_date',
              'order_id',
              'shipping_id',
              'business',
              'channel',
              'totalItem',
            ]"
            :key="tr"
            :class="[tr === 'totalItem' ? 'col-10p' : 'col', 'text-truncate', tr === 'checkbox' ? 'w-8' : '']"
          >
            <a-checkbox
              v-if="tr === 'checkbox' && total_box > 0"
              :indeterminate="indeterminate"
              :checked="checked_all"
              :disabled="loadingList"
              class="fulfillment-checkbox"
              @change="checkboxSelectAllSubmitted"
            />
            <span v-else-if="tr !== 'checkbox'">{{ $t('fulfillment.' + tr) }}</span>
          </div>
          <div class="col-2 px-1 text-truncate">
            Status
          </div>
        </div>
      </div>

      <LoadingListTable v-if="loadingList" class="my-2" />
      <template v-else>
        <ErrorInfo v-if="errorRows" :error-type="errorRows" />
        <template v-else>
          <template v-if="fulfillment.length">
            <FufillmentList
              v-for="(item, index) in fulfillment"
              :key="index"
              :item="item"
              :item-num="index"
              :position="position"
              :item-checked="getItemChecked(item.order_id)"
              :active-categories="activeCategories"
              :permission="permission"
              :width="listWidth"
              class="mt-2"
              @handleItemChecked="handleItemChecked"
              @handleItemSubmitted="handleItemSubmitted"
              @setOrderIdToUpdated="setOrderIdToUpdated"
              @setModalInformationProcessingState="setModalInformationProcessingState"
              @setHaveActionButton="setHaveActionButton"
              @setShowModalPrintLabel="setShowModalPrintLabel"
            />
          </template>
          <EmptyInfo
            v-if="!fulfillment.length && isFetch"
            class="py-5"
            :title="$t('order.empty_title')"
            :description="$t('order.empty_msg')"
          />
        </template>
      </template>
    </a-col>

    <a-col v-if="fulfillment.length" :span="24" style="margin-top: 24px" class="d-flex justify-content-end">
      <a-button
        v-if="tempFulfillment.length"
        type="primary"
        style="margin-right: auto"
        :disabled="loadingList"
        @click="handleSubmitToWms"
      >
        Submit to WMS
      </a-button>
      <a-pagination
        :default-current="page"
        :page-size="limit"
        :page-size-options="sizeOptions"
        :total="total_row"
        :show-total="(total, range) => $t('utils.pagination_show_total', { perpage: `${range[0]} - ${range[1]}`, total })"
        show-size-changer
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      />
    </a-col>

    <ModalProcessingState
      :show-modal="modalInformationProcessingState.show"
      :modal-information-processing-state="modalInformationProcessingState"
      @setModalInformationProcessingState="setModalInformationProcessingState"
      @triggerFulfillmentList="triggerFulfillmentList"
    />

    <!-- Modal Confirmation Print Label -->
    <a-modal
      :visible="showModalPrintLabel"
      :footer="null"
      class="modal-accept-order"
      @cancel="() => setShowModalPrintLabel({ show: false })"
    >
      <a-row>
        <a-col :span="24" class="d-flex justify-content-center">
          <div class="pbz-font subtitle-md-medium text-confirmation">
            Print Label Pesanan
          </div>
        </a-col>

        <a-col :span="24" class="mt-3 d-flex justify-content-center">
          <div class="pbz-font text-caption">
            <div style="color: #999999">
              Cetak alamat pengiriman disertai dengan detail barang?
            </div>
          </div>
        </a-col>

        <a-col :span="24" class="mt-5 d-flex justify-content-center align-items-center">
          <div
            class="save pbz-font button-sm-medium d-flex justify-content-center align-items-center"
            style="
                border: 1px solid #E00000;
                background: #E00000;
                color: #FFFFFF;
                width: 100%;
                padding: .75rem;
                border-radius: 5px;
                cursor: pointer;
              "
            @click="toLabelPage(true)"
          >
            <div>
              <div>Ya, dengan detail barang</div>
            </div>
          </div>
        </a-col>

        <a-col :span="24" class="d-flex justify-content-center align-items-center mt-2">
          <a-button
            class="cancel pbz-font button-sm-medium"
            style="
                width: 100%;
                height: 100%;
                color: #E00000;
                padding: .75rem;
                cursor: pointer;
                border: 1px solid #E00000;
                border-radius: 5px;
              "
            @click.prevent="toLabelPage(false)"
          >
            Tidak, hanya alamat saja
          </a-button>
        </a-col>
      </a-row>
    </a-modal>
  </a-row>
</template>

<script>
import SearchIcon from '@/components/Icons/Search.vue'
import FilterIcon from '@/components/Icons/Filter.vue'
import FilterList from '@/components/DeliveryOrder/filter/index.vue'
import ModalProcessingState from '@/components/DeliveryOrder/ModalProcessingState/index.vue'
import { getExistingUrlFulfillment, getUrlAfterDetailFulfillment } from '@/utils/fulfillment/index'
import ContentModalNotifToSetFilter from '@/components/DeliveryOrder/ContentModalNotifToSetFilter'
import FufillmentList from '@/components/DeliveryOrder/item/list.vue'
import LoadingListTable from '@/components/Loading/ListTable'
import ErrorInfo from '@/components/ErrorInfo'
import EmptyInfo from '@/components/EmptyInfo'
import debounce from 'lodash/debounce'
import getQueryParam from '@/utils/getQueryParam'
import { getFulfillmentList, getFulfillmentDetail, submitToWMS } from '@/api/fulfillment'
import { resynchronizeOrder } from '@/api/channels/index'

const state = [
  {
    value: 'all',
    title: 'Semua',
  },
  {
    value: 'created',
    title: 'Baru',
  },
  {
    value: 'confirmed,accepted',
    title: 'Lakukan Proses',
  },
  {
    value: 'pick_confirmed',
    title: 'Pengambilan',
  },
  {
    value: 'packing_completed',
    title: 'Pengemasan',
  },
  // {
  //   value: 'good_issued',
  //   title: 'Good Issued',
  // },
  {
    value: 'good_issued',
    title: 'Dalam Pengiriman',
  },
  {
    value: 'delivered',
    title: 'Terkirim',
  },
  {
    value: 'canceled',
    title: 'Batal',
  },
  {
    value: 'cancel_requested',
    title: 'Request Pembatalan',
  },
]

const setTableWidth = () => {
  const { width } = window.screen
  return width < 821 ? 1190 : width - 343
}

export default {
  components: {
    SearchIcon,
    FilterIcon,
    FilterList,
    ContentModalNotifToSetFilter,
    ModalProcessingState,
    FufillmentList,
    LoadingListTable,
    EmptyInfo,
    ErrorInfo,
  },
  mixins: [getQueryParam],
  data: function () {
    return {
      showModalPrintLabel: false,
      detailLabel: null,
      loadingList: true,
      errorRows: false,
      isFetch: false,
      categorie_state: state,
      warehouseId: null,
      item_checked: [],
      all_item_checked: false,
      indeterminate_checked: false,
      indeterminate: false,
      checked_all: false,
      fulfillment: [],
      tempFulfillment: [],
      sizeOptions: ['25', '50', '100', '150'],
      total_row: 0,
      total_box: 0,
      filter: false,
      clear: false,
      position: {
        x: 0,
        y: 0,
      },
      inventory_state: [
        {
          value: 'SUCCESS_ALLOCATED',
          title: 'Teralokasi',
          status: '',
        },
        {
          value: 'NOT_YET_ALLOCATED',
          title: 'Belum Teralokasi',
          status: '',
        },
        {
          value: 'ALLOCATION_FAILED',
          title: 'Alokasi Gagal',
          status: '',
        },
      ],
      warehouse_list: [],
      show_button_bulk: false,
      showModalNotifToSetFilter: false,
      orderIdToUpdated: null,
      timeout: 0,
      querySearch: undefined,
      modalInformationProcessingState: {
        show: false,
        orderId: null,
        fulfillmentId: null,
        state: null,
        action: null,
      },
      isHaveActionButton: false,
      listWidth: setTableWidth(),
      flagResync: false,
    }
  },
  computed: {
    businessId() {
      return this.$store.state.user.restriction_base === 'Warehouse'
        ? Object.keys(this.$store.getters['user/businessListObjectByKey'])[0]
        : String(this.$route.query['business_id'] || '')
    },
    activeCategories: function () {
      if (!this.$route.query.fulfillment_state && !this.$route.query.shipping_state) {
        return 'all'
      }
      const find = this.categorie_state.find(item => item.value === (this.$route.query.fulfillment_state || this.$route.query.shipping_state))
      return find.value
    },
    page: function () {
      return +this.$route.query.page || 1
    },
    limit: function () {
      return +this.$route.query.limit || 25
    },
    getPathUrl: function () {
      return getExistingUrlFulfillment({
        meta_key_url: this.$route.meta.key,
        id: this.$route.params.id,
        channel_type: this.$route.params.channelType,
      })
    },
    getFilterParam() {
      const warehouse = this.warehouseId
      if (this.$route.query.start_date || this.$route.query.end_date || this.$route.query.booking_state || warehouse || this.$route.query.service_type || this.$route.query.channel_id) {
        return true
      } else {
        return false
      }
    },
    activeFilter() {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const bookingParam = this.$route.query.booking_state ? this.$route.query.booking_state.split(',') : null
      const warehouseParam = this.warehouseId ? this.warehouseId?.split(',') : null
      const channelParam = this.$route.query.channel_id ? this.$store.state.business.listChannels.find(channel => channel.id == this.$route.query.channel_id) : null
      const start_date = this.$moment(this.$route.query.start_date).format('DD MMM YYYY HH:mm:ss')
      const end_date = this.$moment(this.$route.query.end_date).format('DD MMM YYYY HH:mm:ss')
      const date = this.$route.query.start_date ? [start_date, end_date].join(' - ') : null
      const booking_state = bookingParam ? this.inventory_state.filter(elem => bookingParam.find(item => elem.value === item)).map(item => { return item.title }) : []
      const warehouse_id = warehouseParam ? this.warehouse_list.filter(elem => warehouseParam.find(item => elem.warehouse_id === item)).map(item => { return item.name }) : []
      const channel_id = channelParam?.id ? channelParam.title : null
      const service_type = this.$route.query.service_type ? 'Kurir Instan' : null
      const activeValue = [
        ...booking_state,
        ...warehouse_id,
        channel_id,
        date,
        service_type,
      ]
      return activeValue.filter(item => item !== null)
    },
    isMasterFulfillment: function () {
      return this.$route.meta.key === 'delivery-order' || this.$route.meta.key === 'delivery-order-detail'
    },
    getChannelCodeBasedOnroute () {
      const channel = this.$store.state.user.channelNavigation.find(
        item => +item.id === +this.$route.params.id && item.type === this.$route.params.channelType,
      )

      return channel ? channel.sales_channel_code : ''
    },
    getUrlAfterDetailFulfillmentPage: function () {
      return getUrlAfterDetailFulfillment({
        meta_key_url: this.$route.meta.key,
        id: this.$route.params.id,
        channel_type: this.$route.params.channelType,
      })
    },
    captionBulkyUpdate () {
      switch (this.activeCategories) {
        case 'created': {
          return 'Terima'
        }

        case 'confirmed,accepted': {
          return 'Diambil'
        }

        case 'pick_confirmed': {
          return 'Dikemas'
        }

        case 'packing_completed': {
          return 'Dikirim'
        }

        default:
          return ''
      }
    },
    screenHeight() {
      return ((screen.height - 900) * 0.5) + (screen.height * 0.5)
    },
    permission() {
      return this.$store.getters['user/can']('fulfillment')
    },
  },
  watch: {
    '$route.query.workspace_id'() {
      this.init()
    },
    '$route.query.business_id'() {
      this.fetchFulfillmentList()
    },
    '$route.query.warehouse_id'(value) {
      this.warehouseId = this.$store.state.user.restriction_base === 'Warehouse' ? value : null
      this.fetchFulfillmentList()
    },
    '$route.query.channel_id'() {
      this.fetchFulfillmentList()
    },
    '$route.query.fulfillment_state'() {
      this.fetchFulfillmentList()
    },
    '$route.query.shipping_state'() {
      this.fetchFulfillmentList()
    },
    '$route.query.booking_state'() {
      this.fetchFulfillmentList()
    },
    '$route.query.start_date'() {
      this.fetchFulfillmentList()
    },
    '$route.query.end_date'() {
      this.fetchFulfillmentList()
    },
    '$route.query.q'() {
      this.fetchFulfillmentList()
    },
    '$route.query.order_number_many'() {
      this.fetchFulfillmentList()
    },
    '$route.query.page'() {
      this.fetchFulfillmentList()
    },
    '$route.query.limit'() {
      this.fetchFulfillmentList()
    },
    '$route.query'() {
      this.all_item_checked = false
      this.indeterminate = false
      this.checked_all = false
      this.tempFulfillment = []
    },
    warehouseId: {
      deep: true,
      immediate: true,
      handler: function () {
        if (this.$store.state.user.restriction_base !== 'Warehouse') {
          this.fetchFulfillmentList()
        }
      },
    },
    item_checked: {
      deep: true,
      immediate: true,
      handler: function (value) {
        let countCreatedRow = 0

        value.forEach(item => {
          if (item.checked) {
            countCreatedRow++
          }
        })

        if (countCreatedRow > 0) {
          this.show_button_bulk = true
        } else {
          this.show_button_bulk = false
        }
      },
    },
    // permission(newValue) {
    //   if(!this.$route.query.business_id && !newValue.length) {
    //     this.$router.push({path: '/error/403', query: {...this.$route.query}})
    //   }
    // },
  },
  mounted () {
    this.init()
  },
  methods: {
    async init() {
      await Promise.all([
        this.fetchWarehouseList(),
        this.fetchFulfillmentList(),
      ])
    },
    setLoading(value) {
      this.loadingList = value
    },
    setWarehouseId(value) {
      this.warehouseId = value
    },
    fetchFulfillmentList: debounce(async function() {
      this.errorRows = false
      this.isFetch = false
      const isDelivered = this.$route.query.shipping_state === 'delivered'
      const q = this.$route.query.fulfillment_state?.toUpperCase()
      const query = {
        ...this.$route.query,
        page: this.page,
        limit: this.limit,
        fulfillment_state: this.$route.query.shipping_state === 'delivered' ? 'GOOD_ISSUED' : q,
        shipping_state: this.$route.query.shipping_state === 'delivered'
            ? 'DELIVERED'
            : this.$route.query.shipping_state === 'good_issued'
            ? 'CREATED,SHIPPED'
            : undefined,
        warehouse_id: this.warehouseId,
      }
      if (this.$route.params.channelType && +this.$route.params.id) {
        query[this.$route.params.channelType] = +this.$route.params.id
      }

      await getFulfillmentList({
        url: 'delivery-order',
        params: query,
      })
      .then(({ data: { deliveryOrderList: response, totalRow: total_row } }) => {
        this.fulfillment = []
        this.total_box = 0
        this.total_row = total_row || 0
        response?.forEach(item => {
          if(item.is_ext_wms && !item.channel_is_wms_auto_submit && !item.is_wms_submitted 
            && (item.fulfillment_state === 'ACCEPTED' || (item.fulfillment_state === 'CREATED' && item.order_state === 'ACCEPTED'))
          ) {
            item.show_box = true
            item.submitted = false
          }
          if (this.$route.query.fulfillment_state === 'good_issued' || this.$route.query.shipping_state) {
            if((isDelivered) && (item.shipping_state == 'DELIVERED' || item.shipping_state == 'BUYER_ACCEPTED')) {
              this.fulfillment.push(item)
              if(item?.show_box) this.total_box++
            } else if ((!isDelivered) && (item.shipping_state == 'SHIPPED')) {
              this.fulfillment.push(item)
              if(item?.show_box) this.total_box++
            }
          } else {
            this.fulfillment.push(item)
            if(item?.show_box) this.total_box++
          }
        })
        if (Array.isArray(this.fulfillment) && this.fulfillment.length > 0) {
          this.item_checked = this.fulfillment.map(item => ({
            order_id: item.order_id,
            fulfillment_id: item.fulfillment_id,
            checked: false,
            fulfillment_state: item.fulfillment_state,
          }))
        }
      })
      .catch((err) => {
        this.errorRows = err.response.status
        console.error(err)
      })
      .finally(() => {
        this.loadingList = false
        this.isFetch = true
      })
    }, 500),
    changeCategorieState: function (value) {
      this.loadingList = true
      this.$router.push({
        path: this.getPathUrl,
        query: {
          ...this.$route.query,
          fulfillment_state: value === 'all' || value === 'delivered' ? undefined : value,
          fulfillment_id: undefined,
          shipping_state: value === 'delivered' || value === 'good_issued' ? value : undefined,
          page: 1,
        },
      })
    },
    checkboxSelectAllFulfillment: function (e) {
      this.item_checked = this.item_checked.map((item) => ({
        ...item,
        checked: e.target.checked,
      }))
      this.all_item_checked = e.target.checked
      this.indeterminate_checked = false
    },
    getItemChecked: function (order_id) {
      const data = this.item_checked.find(item => item.order_id === order_id)

      return data ? data.checked : false
    },
    checkboxSelectAllSubmitted(event) {
      this.checked_all = event.target.checked
      this.fulfillment.forEach((item, index) => {
        if(item.show_box) {
          this.handleItemSubmitted({
            index,
            checked: event.target.checked,
            item,
          })
        }
      })
    },
    handleItemSubmitted(obj) {
      this.fulfillment[obj.index].submitted = obj.checked
      const index = this.tempFulfillment.findIndex(fulfillment => fulfillment.id === obj.item.fulfillment_id)
      if(obj.checked && index < 0) {
        const { fulfillment_id, order_id, order_number, channel_id, warehouse_id, channel_code } = obj.item
        const findWarehouse = this.warehouse_list.find(warehouse => warehouse.id === warehouse_id)
        this.tempFulfillment.push({
          id: fulfillment_id,
          order_id,
          order_number,
          channel_id,
          warehouse_id,
          wms_code: findWarehouse?.wms_code || '',
          channel_code,
        })
      } else if(!obj.checked && index > -1) {
        this.tempFulfillment.splice(index, 1)
      }
      
      this.indeterminate = this.tempFulfillment.length > 0 && this.tempFulfillment.length < this.total_box
      this.checked_all = this.tempFulfillment.length == this.total_box
    },
    async handleSubmitToWms() {
      this.loadingList = true
      await submitToWMS({
        business_id: this.businessId,
        data: {
          data: this.tempFulfillment,
        },
      })
      .then(() => {
        this.indeterminate = false
        this.checked_all = false
        this.tempFulfillment = []
        this.$notification.success({
          message: 'Submit Berhasil',
          description: 'Apabila data fulfillment belum berubah, mohon tunggu beberapa saat dan refresh ulang data fulfillment',
        })
        this.fetchFulfillmentList()
      })
      .catch(err => {
        this.$notification.error({
          message: 'Submit Gagal',
          description: 'Mohon hubungi Admin terkait kendala Submit to WMS',
        })
        console.error(err)
      })
      .finally(() => this.loadingList = false)
    },
    handleItemChecked: function ({ checked, order_id }) {
      this.item_checked = this.item_checked.map(item => {
        if (item.order_id === order_id) {
          return {
            ...item,
            checked,
          }
        } else {
          return item
        }
      })

      let countItemSelected = 0

      this.item_checked.forEach((item) => {
        if (item.checked) countItemSelected++
      })

      if (countItemSelected === this.item_checked.length) {
        this.all_item_checked = true
        this.indeterminate_checked = false
      }

      if (
        countItemSelected > 0 &&
        countItemSelected !== this.item_checked.length
      ) {
        this.indeterminate_checked = true
        this.all_item_checked = false
      }

      if (countItemSelected === 0) {
        this.all_item_checked = false
        this.indeterminate_checked = false
      }
    },
    changePage: function (value) {
      this.loadingList = true
      this.$router.push({
        path: this.getPathUrl,
        query: { ...this.$route.query, page: value, order_id: undefined, fulfillment_id: undefined },
      })
    },
    onShowSizeChange(current, pageSize) {
      this.loadingList = true
      this.$router.push({
        path: this.getPathUrl,
        query: { ...this.$route.query, page: current, limit: pageSize, order_id: undefined, fulfillment_id: undefined },
      })
    },
    handleFilter() {
      if (this.filter === true) {
        this.filter = false
      } else {
        this.filter = true
      }
    },
    cancelModal(e) {
      this.filter = e
    },
    resetFilter() {
      this.loadingList = true
      let temp = {
        ...this.$route.query,
        booking_state: undefined,
        warehouse_id: this.$store.state.user.restriction_base === 'Warehouse' ? this.$route.query.warehouse_id : undefined,
        channel_id: undefined,
        start_date: undefined,
        end_date: undefined,
        service_type: undefined,
      }
      this.warehouseId = null
      this.clear = !this.clear
      this.$router.push({
        path: this.getPathUrl,
        query: temp,
      })
    },
    handleOnClickButtonExoport: function () {
      if (!this.activeFilter.length) {
        this.showModalNotifToSetFilter = true
      }
    },
    fetchWarehouseList: function() {
      this.$store
        .dispatch('warehouse/GETSALESWAREHOUSE', {
          page: 1,
          limit: 20,
        })
        .then(({ data }) => {
          this.warehouse_list = data
          this.warehouseId = this.$store.state.user.restriction_base === 'Warehouse' ? this.$route.query.warehouse_id : null
        })
        .catch((err) => {
          console.error(err)
        })
    },
    exportHistory() {
      this.$router.push({
        path: 'delivery-order/export',
      })
    },
    setOrderIdToUpdated (value) {
      this.orderIdToUpdated = value
    },
    handleButtonAcceptBulkOrder () {
      let action

      switch (this.activeCategories) {
        case 'created': {
          action = 'accept-order'
          break
        }

        case 'confirmed,accepted': {
          action = 'pick-confirmed'
          break
        }

        case 'pick_confirmed': {
          action = 'pack-confirmed'
          break
        }

        case 'packing_completed': {
          action = 'packing-completed'
          break
        }

        default:
          break
      }

      this.modalInformationProcessingState = {
        show: true,
        orderId: this.item_checked.map(item => item.order_id),
        fulfillmentId: this.item_checked.map(item => item.fulfillment_id),
        state: '',
        action,
        channelCode: this.getChannelCodeBasedOnroute,
      }
    },
    handleSearch (e) {
      this.loadingList = true
      const value = e.target.value
      this.querySearch = value.length ? value : undefined
      return this.$router.push({
        path: this.isMasterFulfillment ? '/delivery-order' : `/channel/${this.$route.params.id}/${this.$route.params.channelType}/fulfillment`,
        query: {
          ...this.getQueryParam(this.$route.query),
          q: value.includes(';')
            ? undefined
            : value,
          order_number_many: value.includes(';')
            ? value
            : undefined,
          page: 1,
        },
      })
    },
    setModalInformationProcessingState (value) {
      this.modalInformationProcessingState = value
    },
    setHaveActionButton (value) {
      this.isHaveActionButton = value
    },
    triggerFulfillmentList () {
      this.fetchFulfillmentList()
    },
    async setShowModalPrintLabel (value) {
      this.loadingList = true
      if (value.show) {
        await getFulfillmentDetail({
          url: 'delivery-order',
          fulfillment_id: value.fulfillmentId,
        })
        .then(({ data: { data: response } }) => {
          if (response.order.channel.code === 'tokopedia_id') {
            if ((!response?.fulfillments?.[0]?.shipping.awb || 
                !response?.fulfillments?.[0]?.shipping.courier || 
                !response?.fulfillments?.[0]?.shipping.shipping_label_url) && !this.flagResync) {
              this.resychronizeShipping(response.order.id, response.order.channel.code, value)
            } else {
              this.flagResync = false
              if (response.fulfillments[0]?.shipping.shipping_label_url) {
                this.detailLabel = value
                window.open(response.fulfillments[0]?.shipping.shipping_label_url, '_blank');
              } else {
                this.showModalPrintLabel = value.show
                this.detailLabel = value
              }
            }
          } else {
            if (response.fulfillments[0]?.shipping.shipping_label_url) {
              this.detailLabel = value
              window.open(response.fulfillments[0]?.shipping.shipping_label_url, '_blank');
            } else {
              this.showModalPrintLabel = value.show
              this.detailLabel = value
            }
          }
          this.loadingList = false
        })
        .catch((err) => {
          this.loadingList = false
          console.error(err)
          this.$notification.error({
            message: 'Maaf, Label Belum Tersedia',
          })
        })
      } else {
        this.showModalPrintLabel = value.show
        this.loadingList = false
      }
    },
    async resychronizeShipping (order_id, channelCode, val) {
      this.loadingList = true
      await resynchronizeOrder({
        business_id: this.$route.query.business_id,
        channel_code: channelCode,
        order_id: order_id,
      })
      .then(() => this.loadingList = true)
      .catch(err => {
          console.error('errornya', err)
          this.loadingList = true
        })
      .finally(() => {
        this.flagResync = true
        setTimeout(() => {
          this.setShowModalPrintLabel(val)
        }, 3000)
      })
    },
    toLabelPage (param) {
      this.$router.push({
        name: this.$route.params.id === undefined ? 'delivery-order-label' : 'channel-fulfillment-label',
        query: {
          ...this.getQueryParam(this.$route.query),
          order_id: this.detailLabel.orderId,
          fulfillment_id: this.detailLabel.fulfillmentId,
        },
        params: {
          label_product: param,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.row-calc > .col {
  padding-left: 9px;
  padding-right: 9px;
  flex-basis: calc(100% / 6); // 100% / 8
  max-width: calc(100% / 6);
  font-size: 13px;
}

.col-10p {
  flex-basis: 10%;
  max-width: 10%;
  font-size: 13px;
}

.salesindex {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;

  .categories {
    .list {
      color: #999;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 14px;
      min-height: 30px;
      background: #fff;
    }

    .list:not(:focus) {
      border-color: #999;
    }

    .active {
      background: #4d4d4d;
      color: #fff;
      border-color: #4d4d4d;
    }

    .list:hover:not(.active) {
      border-color: #4d4d4d;
      color: #4d4d4d;
    }
  }

  .active {
    background: #0559cb;
    color: #fff;
    border: 1px solid #0559cb;
  }
}
</style>

<style lang="scss">
.fulfillment {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;

  .search-box {
    width: 338px;
  }
  .field {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    width: 100%;
    text-align: left;
    padding-left: 2.5rem !important;
    padding: 10px 15px;
    height: 48px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
    color: #999999;
  }
  .search-box > .i {
    position: absolute;
    margin: 1rem 0 0 0.5rem;
  }
  .field::placeholder {
    color: #999999 !important;
  }

  .filter-box {
    width: 93px;
    height: 48px;
    padding: 2px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    div {
      margin: 0 5px;
    }

    .caption {
      font-family: 'Poppins', sans-serif !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #999999;
    }
  }

  .empty {
    .caption-one {
      margin-top: 60px;
      color: #1a1a1a;
    }

    .caption-two {
      margin-top: 10px;
      color: #999999;
    }

    .button-reload {
      padding: 12px 20px;
      position: static;
      width: 401px;
      height: 48px;
      background: #0559cb;
      border-radius: 5px;
      color: #ffffff;
      margin-top: 50px;
      cursor: pointer;
    }
  }

  .categories {
    margin-top: 40px;
    cursor: pointer;

    .list {
      margin: 0px 2px;
      color: #999999;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5px 10px;
      width: auto;
      height: 31px;
      background: #ffffff;
      border: 1px solid #999999;
      box-sizing: border-box;
      border-radius: 33px;
      p {
        margin: 0;
        font-size: 0.9em;
      }
    }

    .active {
      background: #4D4D4D;
      color: #ffffff;
      border: 1px solid #4D4D4D;
    }

    .list:hover:not(.active) {
      border: 1px solid #4D4D4D;
      color: #4D4D4D;
    }
  }

  .export-dropdown {
    padding: 20px 0px;
    width: 258px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}
.reject-button-modal {
  margin: 10px 0px;
  width: 100%;
  border: none;
  color: #E00000;
  border: 1px solid #E00000;
  border-radius: 5px;
  padding: .75rem;
  background: #ffffff;
  cursor: pointer;
}
.accept-button-modal {
  margin: 10px 0px;
  border: 1px solid #E00000;
  background: #E00000;
  color: #FFFFFF;
  width: 100%;
  padding: .75rem;
  border-radius: 5px;
  cursor: pointer;
}
.field-set{
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 20px 0;

  .ant-select-selection.ant-select-selection--single {
    border: 1px solid #ffffff;
    margin-top: -5px;
    margin-bottom: 10px;
  }
}
.w-8 {
  flex-basis: 4% !important;
  max-width: 4% !important;
  -ms-flex-preferred-size: 4% !important;
}
.fulfillment-checkbox .ant-checkbox-inner {
  border-color: #999 !important;
}
</style>
